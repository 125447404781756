export function useSeo() {
  const getMetaRobots = (data) => {
    const result = [];
    result.push(data?.seoNoIndex ? "noindex" : "index");
    result.push(data?.seoNoFollow ? "nofollow" : "follow");
    return result.join(", ");
  };

  const hasMetaTags = (data) => {
    if (!data) return false;
    return Object.entries(data).some(
      ([key, value]) => key !== "seoTitle" && value,
    );
  };

  const generateMetaTags = (data) => {
    const tags = [];
    if (data?.seoDescription) {
      tags.push({
        name: "description",
        content: data.seoDescription,
      });
    }
    if (data?.ogTitle) {
      tags.push({
        property: "og:title",
        content: data.ogTitle,
      });
    }
    if (data?.ogDescription) {
      tags.push({
        property: "og:description",
        content: data.ogDescription,
      });
    }
    if (data?.ogImage?.length > 0) {
      tags.push({
        property: "og:image",
        content: data.ogImage?.[0]?.url ?? "",
      });
    }
    tags.push({
      name: "robots",
      content: getMetaRobots(data),
    });
    return tags;
  };

  const getSeoMetaData = (data) => {
    return {
      ...(data?.seoTitle ? { title: data.seoTitle } : {}),
      ...(hasMetaTags(data)
        ? {
            meta: generateMetaTags(data),
          }
        : {}),
    };
  };

  return {
    getSeoMetaData,
  };
}
